<template>
  <div style="display: flex; flex-direction: column; height: calc(100vh - 180px)">
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item" v-if="searchButton">
        <label class="fn-14">订单状态</label>
        <el-select
          style="width: 120px"
          clearable
          v-model="searchData.status"
          placeholder="订单状态"
          autocomplete="off"
          size="small"
        >
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="condition-item" v-if="searchButton">
        <label class="fn-14">订单号</label>
        <el-input
          v-model="searchData.orderNo"
          placeholder="订单号"
          clearable
          autocomplete="off"
          size="small"
        ></el-input>
      </div>
      <div class="condition-item" v-if="searchButton">
        <label class="fn-14">学校</label>
        <el-select
          clearable
          filterable
          v-model="searchData.schoolId"
          remote
          reserve-keyword
          placeholder="请输入学校名或代码检索"
          :remote-method="remoteMethod"
          :loading="loading"
          autocomplete="off"
          size="small"
        >
          <el-option
            v-for="item in schools"
            :key="item.id"
            :label="item.schoolFilterName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="condition-item" v-if="searchButton">
        <label class="fn-14">学生姓名</label>
        <el-input
          style="width: 120px"
          v-model="searchData.studentName"
          placeholder="学生姓名"
          autocomplete="off"
          clearable
          size="small"
        >
        </el-input>
      </div>
      <div class="condition-item" v-if="searchButton">
        <label class="fn-14">手机号</label>
        <el-input
          style="width: 120px"
          v-model="searchData.tel"
          clearable
          placeholder="手机号"
          autocomplete="off"
          size="small"
        ></el-input>
      </div>
      <div class="condition-item" v-if="searchButton">
        <label class="fn-14">身份证号</label>
        <el-input
          style="width: 180px"
          v-model="searchData.idNumber"
          placeholder="身份证号"
          autocomplete="off"
          clearable
          size="small"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">支付金额</label>
        <el-input
          v-model="searchData.shotPrice"
          placeholder="请输入支付金额"
          autocomplete="off"
          size="small"
          style="width: 130px"
          clearable
        >
        </el-input>
      </div>
      <div class="condition-item" v-if="searchButton">
        <label class="fn-14">创建时间</label>
        <el-date-picker
          style="width: 160px"
          v-model="searchData.stime"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择开始时间"
          autocomplete="off"
          size="small"
        >
        </el-date-picker>
        <label class="fn-14" style="padding-left: 10px">-</label>
        <el-date-picker
          v-model="searchData.etime"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择结束 时间"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
      </div>
      <div class="condition-item">
        <label class="fn-14">采集码</label>
        <el-input
          style="width: 130px"
          v-model="searchData.cjmn"
          placeholder="请输入采集码"
          autocomplete="off"
          size="small"
          clearable
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">学号</label>
        <el-input
          style="width: 130px"
          v-model="searchData.studentCode"
          placeholder="请输入学号"
          autocomplete="off"
          size="small"
          clearable
        ></el-input>
      </div>
      <div class="condition-item">
        <el-button
          v-if="searchButton"
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          size="small"
          >查询
        </el-button>
        <el-button
          v-if="searchButton"
          type="info"
          icon="el-icon-refresh"
          @click="resetSearch"
          size="small"
          >重置
        </el-button>
        <el-button
          v-if="downloadSpButton"
          type="primary"
          icon="el-icon-download"
          @click="handleDownloadSp"
          size="small"
          >打包下载
        </el-button>
      </div>
    </div>
    <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
      <el-table
        :data="orderList"
        v-loading="tableLoading"
        stripe
        border
        height="100%"
        style="width: 100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
      >
        <template #empty>
          <p>{{ tableLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>
        <el-table-column prop="orderNo" label="订单号" width="240"></el-table-column>
        <el-table-column prop="studentName" label="学生姓名" width="120" align="center">
          <template slot-scope="scope">
            <span>{{ execDecrypt(scope.row.studentName, "name") }}</span></template
          >
        </el-table-column>
        <el-table-column prop="mobileNumber" label="手机号" width="150" align="center">
          <template slot-scope="scope">
            <span>{{ execDecrypt(scope.row.mobileNumber, "ph") }}</span></template
          >
        </el-table-column>
        <el-table-column prop="idNumber" label="身份证号" width="200" align="center">
          <template slot-scope="scope">
            <span>{{ execDecrypt(scope.row.idNumber, "Idcard") }}</span></template
          >
        </el-table-column>
        <el-table-column prop="schoolName" label="学校" width="260"> </el-table-column>
        <el-table-column prop="studentCode" label="学号" width="180"> </el-table-column>
        <el-table-column
          prop="createTime"
          :formatter="columnDateFormat"
          label="下单时间"
          width="160"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="status" label="订单状态" width="80" align="center">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.status == 0"
              type="warning"
              effect="plain"
              size="small"
              >下单</el-tag
            >
            <el-tag
              v-else-if="scope.row.status == 1"
              type="success"
              effect="plain"
              size="small"
              >预付款
            </el-tag>
            <el-tag v-else-if="scope.row.status == 2" type="" effect="plain" size="small"
              >完成</el-tag
            >
            <el-tag
              v-else-if="scope.row.status == 3"
              type="danger"
              effect="plain"
              size="small"
              >取消</el-tag
            >
            <el-tag
              v-else-if="scope.row.status == 4"
              type="danger"
              effect="plain"
              size="small"
              >支付中</el-tag
            >
            <el-tag
              v-else-if="scope.row.status == 5"
              type="danger"
              effect="plain"
              size="small"
              >取消中</el-tag
            >
            <el-tag
              v-else-if="scope.row.status == 6"
              type="danger"
              effect="plain"
              size="small"
              >预取消</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="payType" label="支付类型" width="120" align="center">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.payType === 0"
              type="success"
              effect="plain"
              size="small"
              >线下支付</el-tag
            >
            <el-tag
              v-else-if="scope.row.payType === 1"
              type="success"
              effect="plain"
              size="small"
              >工行E支付
            </el-tag>
            <el-tag
              v-else-if="scope.row.payType === 2"
              type="success"
              effect="plain"
              size="small"
              >备用支付
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="shotPrice" label="金额" width="70" align="center">
        </el-table-column>
        <!-- <el-table-column prop="schoolArea" label="校区" width="120">
        </el-table-column>
        <el-table-column prop="schoolDept" label="院系" width="120">
        </el-table-column>
        <el-table-column prop="examineeNumber" label="考生号" width="120">
        </el-table-column> -->
        <el-table-column prop="classes" label="班级" width="120" align="center">
        </el-table-column>
        <el-table-column prop="major" label="专业" width="120" align="center">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="300" align="center">
          <template slot-scope="scope">
            <el-button v-if="viewButton" type="text" @click="handleView(scope.row.id)"
              >查看
            </el-button>
            <el-button
              v-if="uploadButton"
              type="text"
              v-show="scope.row.status === 1"
              @click="handleImport(scope.row.id)"
              >上传照片
            </el-button>
            <el-button
              v-if="reUploadButton"
              type="text"
              v-show="scope.row.status === 2"
              @click="handleReImport(scope.row.id, scope.row)"
              >重传照片
            </el-button>
            <el-button
              v-if="cencelButton"
              type="text"
              v-show="scope.row.status == 0 || scope.row.status == 1"
              @click="handleCencelPay(scope.row.id)"
              >取消订单
            </el-button>
            <el-button
              v-if="mailButton"
              type="text"
              v-show="scope.row.status != 3 && !scope.row.batchId"
              @click="handleMail(scope.row.id)"
            >
              修改邮寄地址
            </el-button>
            <el-button
              v-if="orderFlowByorderNo"
              type="text"
              @click="handleOrderLogs(scope.row)"
            >
              订单支付日志
            </el-button>
            <el-button v-if="showOrderLogSp" type="text" @click="showOrderLog(scope.row)">
              订单日志
            </el-button>
            <el-button
              v-if="Logisticsbutton"
              type="text"
              v-show="scope.row.status == 2"
              @click="handleLogistics(scope.row)"
            >
              物流查询
            </el-button>
            <el-button
              type="text"
              v-if="showforcedCancell"
              v-show="scope.row.status == 2"
              @click="forcedCancell(scope.row.id)"
              >强制取消
            </el-button>
            <el-button
              type="text"
              v-if="showforcedCancell"
              v-show="scope.row.isCjm == 0"
              @click="cjmUpload(scope.row.id)"
              >上传采集码
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display: flex; justify-content: right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!--    上传学生照片-->
    <el-dialog
      title="上传学生照片"
      :visible.sync="dlgImportVisible"
      width="500px"
      center="center"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form :rules="importRules" :model="fileForm" ref="fileForm" label-width="100px">
        <el-form-item label="重传原因：" prop="mode" v-show="uploadImgType == 2">
          <el-radio-group v-model="fileForm.type">
            <el-radio :label="1">图片处理</el-radio>
            <el-radio :label="2">增值服务</el-radio>
            <el-radio :label="3">勘误</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上传照片：" prop="file" style="margin-bottom: 0">
          <el-row class="photo-box">
            <el-col :span="9" style="margin-right: 20px" v-show="uploadImgType == 2">
              <div class="item">
                <el-image :src="photoImg" class="photo">
                  <div slot="placeholder" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <div class="desc">原照片</div>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="item">
                <el-upload
                  class="upload-demo"
                  ref="uploadImport"
                  :multiple="false"
                  action=""
                  :limit="1"
                  :show-file-list="false"
                  :on-change="handleChange"
                  :file-list="fileList"
                  accept="image/jpeg,image/png"
                  list-type="picture"
                  :auto-upload="false"
                >
                  <el-image :src="uploadImg" class="photo">
                    <div slot="placeholder" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </el-upload>
                <div class="desc">
                  {{ uploadImgType == 2 ? "重传后的照片" : "上传照片" }}
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="人像识别：" prop="mode">
          <el-radio-group v-model="fileForm.isFaceDetect">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">不启用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="日志" prop="importMsg" v-if="importMsg">
          <el-alert
            :visible.sync="msgVisible"
            v-model="importMsg"
            :title="importMsg"
            show-icon
            :type="importSatus"
          >
          </el-alert>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin: 30px 0 10px 0; text-align: center"
      >
        <el-button @click="dlgImportVisible = false">关 闭</el-button>
        <el-button type="primary" :loading="uploadLoading" @click="submitUpload"
          >确认更新</el-button
        >
      </div>
    </el-dialog>

    <!--散拍照片下载-->
    <el-dialog
      title="散拍照片下载"
      :visible.sync="dlgDownloadVisible"
      width="600px"
      center="center"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :rules="downloadRules"
        :model="downloadForm"
        ref="downloadForm"
        label-width="150px"
      >
        <el-form-item label="采集开始日期" prop="collectedTimeBegin">
          <el-date-picker
            v-model="downloadForm.collectedTimeBegin"
            type="date"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            placeholder="选择采集开始日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="采集结束日期" prop="collectedTimeEnd">
          <el-date-picker
            v-model="downloadForm.collectedTimeEnd"
            type="date"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            placeholder="选择采集结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="打包模式" prop="mode">
          <el-radio-group v-model="downloadForm.mode">
            <el-radio :label="1">流水号</el-radio>
            <el-radio :label="2">学号+姓名+身份证号</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin: 0px 0 10px 0; text-align: center"
      >
        <el-button
          style="margin-left: 10px"
          type="success"
          @click="handleBgeinDownload"
          :loading="confirmLoading"
          >下载
        </el-button>
        <el-button @click="dlgDownloadVisible = false">关 闭</el-button>
      </div>
    </el-dialog>

    <!--修改邮寄地址-->
    <el-dialog
      title="修改邮寄地址"
      :visible.sync="dlgMailVisible"
      width="600px"
      center="center"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form :model="mailForm" ref="mailForm" label-width="80px">
        <el-form-item label="省" prop="regionProvince">
          <el-select
            v-model="mailForm.regionProvince"
            @change="getCity(mailForm.regionProvince)"
            placeholder="请选择省"
            style="width: 100%"
          >
            <el-option
              v-for="item in proviceOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市" prop="regionCity">
          <el-select
            v-model="mailForm.regionCity"
            @change="getCounty(mailForm.regionCity)"
            placeholder="请选择市"
            style="width: 100%"
          >
            <el-option
              v-for="item in cityOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="县" prop="regionCounty">
          <el-select
            v-model="mailForm.regionCounty"
            placeholder="请选择县"
            style="width: 100%"
          >
            <el-option
              v-for="item in countyOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系人" prop="contactPerson">
          <el-input
            type="text"
            v-model="mailForm.contactPerson"
            placeholder="请输入联系人"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="contactTell">
          <el-input
            type="text"
            v-model="mailForm.contactTell"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮寄地址" prop="mailingAdress">
          <el-input
            type="text"
            v-model="mailForm.mailingAdress"
            placeholder="请输入邮寄地址"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin: 30px 0 10px 0; text-align: center"
      >
        <el-button style="margin-left: 10px" type="success" @click="handleUpdateMail"
          >保存
        </el-button>
        <el-button @click="dlgMailVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 物流查询 -->
    <div>
      <el-dialog
        title="物流查询"
        :visible.sync="logisticsVisible"
        width="900px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <div class="logistics_box">
          <span>快递单号:{{ courierNumber }}</span>
          <span style="margin-left: 50px">物流公司:{{ logisticsCompany }}</span>
          <div style="margin-top: 20px; background: #f3f3f3; padding: 30px 0px">
            <div style="width: 200px; margin: 0 auto" v-show="logistics == 0">
              <el-empty :image-size="200" description="当前邮件暂无轨迹信息"></el-empty>
            </div>

            <el-timeline>
              <el-timeline-item
                v-for="(item, index) in logisticsList"
                :key="index"
                :icon="item.icon"
                :type="item.type"
                :color="item.color"
                :size="item.size"
                :timestamp="item.opTime"
              >
                {{ item.opDesc }}
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
        <div slot="footer" class="dialog-footer" style="text-align: center">
          <el-button @click="logisticsVisible = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>

    <el-dialog
      title="订单支付日志列表"
      :visible.sync="orderLogsVisible"
      width="900px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-table :data="OrderFlowList" stripe border height="400" style="width: 100%">
        <el-table-column prop="orderNo" label="订单号"></el-table-column>
        <el-table-column prop="payId" label="工行订单号"></el-table-column>
        <el-table-column prop="status" label="订单状态" align="center">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.status == 0"
              type="warning"
              effect="plain"
              size="small"
              >下单</el-tag
            >
            <el-tag
              v-else-if="scope.row.status == 1"
              type="success"
              effect="plain"
              size="small"
              >预付款
            </el-tag>
            <el-tag v-else-if="scope.row.status == 2" type="" effect="plain" size="small"
              >完成</el-tag
            >
            <el-tag
              v-else-if="scope.row.status == 3"
              type="danger"
              effect="plain"
              size="small"
              >取消</el-tag
            >
            <el-tag
              v-else-if="scope.row.status == 4"
              type="danger"
              effect="plain"
              size="small"
              >支付中</el-tag
            >
            <el-tag
              v-else-if="scope.row.status == 5"
              type="danger"
              effect="plain"
              size="small"
              >取消中</el-tag
            >
            <el-tag
              v-else-if="scope.row.status == 6"
              type="danger"
              effect="plain"
              size="small"
              >预取消</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="isSuccess" label="支付状态">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.isSuccess === 1"
              type="success"
              effect="plain"
              size="small"
              >成功</el-tag
            >
            <el-tag v-else type="danger" effect="plain" size="small">失败</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="isSuccess" label="支付类型">
          <template slot-scope="scope">
            <span v-if="scope.row.payType === 0">线下支付</span>
            <span v-if="scope.row.payType === 1">E支付</span>
            <span v-if="scope.row.payType === 2">备用支付</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="workDatetime"
          label="执行时间"
          :formatter="columnDateFormat"
          width="160"
        ></el-table-column>
        <el-table-column prop="log" label="日志"> </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 订单日志 -->
    <el-dialog
      title="订单日志信息"
      :visible.sync="showOrderView"
      width="900px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-table :data="orderLogsList" stripe border height="400" style="width: 100%">
        <el-table-column prop="username" label="操作人" width="80"></el-table-column>
        <el-table-column prop="title" label="状态" width="140"></el-table-column>
        <el-table-column
          prop="createTime"
          label="执行时间"
          :formatter="columnDateFormat"
          width="160"
        ></el-table-column>
        <el-table-column prop="note" label="日志"> </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 强制取消 -->
    <el-dialog
      title="强制取消"
      :visible.sync="ViewforcedVisible"
      width="600px"
      center="center"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-input
        :rows="5"
        type="textarea"
        v-model="forceCancelReason"
        placeholder="请输入取消原因"
      ></el-input>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin: 0px 0 10px 0; text-align: center"
      >
        <el-button @click="forceddefine" type="primary" :loading="forcedLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 上传采集码 -->
    <el-dialog
      title="上传采集码"
      :visible.sync="batchUplodShow"
      width="350px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-upload
        class="upload-demo"
        ref="uploadImport"
        :multiple="false"
        action=""
        :limit="1"
        :on-change="handleUploadBatch"
        :file-list="[]"
        accept="image/jpeg,image/png,image/jpg"
        :auto-upload="false"
        :show-file-list="false"
      >
        <el-button
          slot="trigger"
          size="small"
          type="primary"
          style="margin-left: 120px"
          :loading="batchUpdateLoading"
          :disabled="batchUpdateLoading"
          >{{ batchUpdateLoading ? "文件上传中..." : "选取文件" }}
        </el-button>
        <!-- <div slot="tip" class="el-upload__tip">
          只能上传xls/xlsx文件，且不超过10M
          <a :href="'/temp/educationTemp_XP.zip?ver=' + times">下载校拍模板</a>
          <a
            :href="'/temp/educationTemp_SP.zip?ver=' + times"
            style="margin-left: 10px"
            >下载散拍模板</a
          >
        </div> -->
      </el-upload>
    </el-dialog>
    <!-- 查看订单详情 -->
    <orderinfo ref="orderInfoFrom" />
  </div>
</template>

<script>
import { getSchoolFiller } from "@/api/school";
import { dateFormat } from "@/utils/date";
import { formatPh, formatName, formatIdCard } from "@/utils/validate";
import orderinfo from "@/components/orderinfo.vue";

import {
  queryOrder,
  getOrder,
  deleteOrder,
  uploadPhoto,
  reUploadPhoto,
  cencelPay,
  getRegionsByParentId,
  updateMailinfo,
  getOrderFlowByOrderNo,
  getOrdersLogs,
  getLogistics,
  forceCancelPay,
  uploadCjm,
} from "@/api/order";

import { queryPageButton } from "@/api/permission";
import { getToken } from "@/utils/auth";
import axios from "axios";

export default {
  name: "OrderSpMgt",
  components: {
    orderinfo, //查看详情
  },
  data() {
    const validateCollectedTimeBegin = (rule, value, callback) => {
      if (!value) {
        callback(new Error("采集开始时间不能为空"));
      } else {
        callback();
      }
    };
    const validateCollectedTimeEnd = (rule, value, callback) => {
      if (!value) {
        callback(new Error("采集结束时间不能为空"));
      } else {
        callback();
      }
    };
    const validateMode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择打包模式"));
      } else {
        callback();
      }
    };
    return {
      searchData: {
        orderNo: "",
        studentName: "",
        tel: "",
        idNumber: "",
        shotPrice: "",
        stime: "",
        etime: "",
        schoolId: null,
        status: null,
        shotTypeCode: "1",
        cjmn: "",
        studentCode: "",
      },

      orderList: [],
      tableLoading: false,
      tableSelection: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      logistics: 0,
      courierNumber: "暂无",
      logisticsCompany: "暂无",
      orderStatusForm: {},
      confirmLoading: false,
      logisticsVisible: false,
      OrderFlowList: [],
      logisticsList: [],
      photoImg: "../temp/none_img.jpg",
      uploadImg: "../temp/none_img.jpg",
      statusOptions: [
        {
          value: "0",
          label: "下单",
        },
        {
          value: "1",
          label: "预付款",
        },
        {
          value: "2",
          label: "完成",
        },
        {
          value: "3",
          label: "取消",
        },
        {
          value: "4",
          label: "支付中",
        },
      ],
      shotTypeOptions: [
        {
          value: "0",
          label: "校拍",
        },
        {
          value: "1",
          label: "散拍",
        },
      ],
      isPhoto: false,
      //学校
      schools: [],
      value: [],
      allSchools: [],
      loading: false,

      //批量导入
      orderId: "",
      msgVisible: false,
      importSatus: "info",
      importMsg: "",
      uploadImgType: 1,
      dlgImportVisible: false,
      uploadLoading: false,
      isReImport: false,
      fileForm: {
        type: 1,
        isFaceDetect: 1,
        name: "",
        file: null,
      },
      hasFile: false,
      fileList: [],
      importRules: {
        file: [{ required: true, message: "请选择上传文件", trigger: "blur" }],
      },

      dlgDownloadVisible: false,
      downloadForm: {},
      downloadRules: {
        collectedTimeBegin: [
          {
            required: true,
            trigger: "blur",
            validator: validateCollectedTimeBegin,
          },
        ],
        collectedTimeEnd: [
          {
            required: true,
            trigger: "blur",
            validator: validateCollectedTimeEnd,
          },
        ],
        mode: [
          {
            required: true,
            trigger: "blur",
            validator: validateMode,
          },
        ],
      },

      //邮寄地址
      dlgMailVisible: false,
      mailForm: {},
      proviceOptions: [],
      cityOptions: [],
      countyOptions: [],

      // 批量上传
      batchUplodShow: false,
      batchUpdateLoading: false,
      batchUploadId: null,

      //强制取消订单
      forcedID: "",
      ViewforcedVisible: false,
      forceCancelReason: "", //取消原因
      forcedLoading: false,

      //按钮权限
      searchButton: false,
      viewButton: false,
      uploadButton: false,
      cencelButton: false,
      downloadSpButton: false,
      reUploadButton: false,
      orderFlowByorderNo: false,
      mailButton: false,
      orderLogsVisible: false,
      showforcedCancell: false, //强制按钮
      showOrderLogSp: false,
      showOrderView: false,
      Logisticsbutton: false,
      orderLogsList: [],
    };
  },
  created() {
    this.queryButton();
    this.query();
    this.querySchools();
  },
  methods: {
    queryButton() {
      const data = 18;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "search") {
            this.searchButton = true;
          }
          if (button.code === "view") {
            this.viewButton = true;
          }
          if (button.code === "upload") {
            this.uploadButton = true;
          }
          if (button.code === "cencel") {
            this.cencelButton = true;
          }
          if (button.code === "downloadSp") {
            this.downloadSpButton = true;
          }
          if (button.code === "reUpload") {
            this.reUploadButton = true;
          }
          if (button.code === "mail") {
            this.mailButton = true;
          }
          if (button.code === "orderFlowByorderNo") {
            this.orderFlowByorderNo = true;
          }
          if (button.code === "showOrderLogSp") {
            this.showOrderLogSp = true;
          }
          if (button.code === "showforcedCancell") {
            this.showforcedCancell = true;
          }
          if (button.code === "Logisticsbutton") {
            this.Logisticsbutton = true;
          }
        }
      });
    },
    querySchools() {
      getSchoolFiller().then((resp) => {
        this.allSchools = resp.data;
        this.schools = resp.data;
      });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.schools = this.allSchools.filter((item) => {
            if (typeof item != undefined) {
              return item.schoolFilterName.indexOf(query) > -1;
            }
          });
        }, 200);
      } else {
        this.schools = [];
      }
    },
    query() {
      this.tableLoading = true;
      queryOrder(this.currentPage, this.pageSize, this.searchData).then((resp) => {
        this.orderList = resp.data.content;
        this.total = resp.data.totalElements;
        this.tableLoading = false;
      });
    },
    handleUpdateMail() {
      const data = this.mailForm;
      updateMailinfo(data)
        .then((resp) => {
          this.$notify({
            title: "修改邮寄地址",
            message: resp.message,
            type: "success",
          });
          this.dlgMailVisible = false;
        })
        .catch((error) => {});
    },
    handleSearch() {
      this.currentPage = 1;
      this.query();
    },
    resetSearch() {
      this.searchData = {};
      this.currentPage = 1;
      this.query();
    },
    handleImport(id) {
      if (this.$refs.fileForm !== undefined) {
        //重置表单信息
        this.$refs.fileForm.resetFields();
      }
      this.orderId = id;
      this.isReImport = false;
      this.dlgImportVisible = true;
      this.uploadImgType = 1;
      this.fileList = [];
      this.importMsg = "";
      this.msgVisible = false;
      this.importSatus = "info";
      this.uploadLoading = false;
      this.uploadImg = "../temp/none_img.jpg";
    },
    //
    handleReImport(id, resp) {
      if (this.$refs.fileForm !== undefined) {
        //重置表单信息
        this.$refs.fileForm.resetFields();
      }
      this.orderId = id;
      this.isReImport = true;
      this.dlgImportVisible = true;
      this.uploadImgType = 2;
      this.fileList = [];
      this.importMsg = "";
      this.msgVisible = false;
      this.importSatus = "info";
      this.uploadLoading = false;
      this.uploadImg = "../temp/none_img.jpg";
      this.photoImg = "../temp/none_img.jpg?timestamp=" + new Date().getTime();
      this.isPhoto = false;
      this.getPhoto(resp.pathCollectedPhoto);
    },
    getPhoto(fileid) {
      const url =
        //window._config["baseUrl"] +
        "/api/csias/file/download/" + fileid + "?timestamp=" + new Date().getTime();
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        responseType: "blob",
        timeout: 180000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            const imgContent = response.data;
            this.photoImg = window.URL.createObjectURL(imgContent);
            this.isPhoto = true;
            // this.init(this.$refs.wrapper);
          }
        },
        (err) => {
          this.$notify.error({
            title: "错误",
            message: "照片加载失败，未查询到照片信息",
          });
        }
      );
    },

    // 上传采集码
    cjmUpload(cjmUploadId) {
      this.cjmUploadId = cjmUploadId;
      this.batchUplodShow = true;
    },
    //批量上传
    async handleUploadBatch(file) {
      this.batchUpdateLoading = true;
      if (file.raw) {
        let form = new FormData();
        form.append("id", this.cjmUploadId);
        form.append("file", file.raw);
        uploadCjm(form)
          .then((res) => {
            if (res.code == 0) {
              this.$notify({
                title: "温馨提示",
                message: res.message,
                type: "success",
              });
            } else {
              this.$notify({
                title: "警告",
                message: res.message,
                type: "warning",
              });
            }
            this.batchUpdateLoading = false;
            this.batchUplodShow = false;
            this.query();
          })
          .catch((err) => {
            this.batchUpdateLoading = false;
            this.batchUplodShow = false;
            this.query();
            this.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          });
      }
    },

    // 强制取消订单
    forcedCancell(id) {
      this.forcedID = id;
      this.forceCancelReason = "";
      this.ViewforcedVisible = true;
    },
    //确定取消
    forceddefine() {
      if (!this.forceCancelReason) {
        this.$notify({
          title: "警告",
          message: "请输入取消原因",
          type: "warning",
        });
        return;
      }
      this.forcedLoading = true;
      let postData = {
        id: this.forcedID,
        forceCancelReason: this.forceCancelReason,
      };
      forceCancelPay(postData)
        .then((res) => {
          if (res.code == 0) {
            this.ViewforcedVisible = false;
            this.$notify({
              title: "温馨提示",
              message: res.message,
              type: "success",
            });
          } else {
            this.ViewforcedVisible = false;
            this.query();
            this.forcedLoading = false;
            this.$notify({
              title: "警告",
              message: res.message,
              type: "warning",
            });
          }
        })
        .catch((err) => {
          {
            this.query();
            this.forcedLoading = false;
            this.ViewforcedVisible = false;
            this.$notify({
              title: "警告",
              message: "未知错误",
              type: "warning",
            });
          }
        });
    },
    /**
     * 查询订单日志
     */
    showOrderLog(data) {
      let that = this;
      getOrdersLogs(data.id).then((res) => {
        if (res.code == 0) {
          that.orderLogsList = res.data;
          that.showOrderView = true;
        }
      });
    },
    handleOrderLogs(data) {
      this.orderLogsVisible = true;
      getOrderFlowByOrderNo(data.orderNo)
        .then((resp) => {
          if (resp.data) {
            this.OrderFlowList = resp.data;
          }
        })
        .catch((error) => {});
    },
    handleMail(id) {
      this.orderId = id;
      this.dlgMailVisible = true;
      this.getProvice();
      getOrder(id)
        .then((resp) => {
          if (resp.data) {
            this.mailForm = resp.data;
            this.mailForm.id = this.orderId;
          }
        })
        .catch((error) => {});
    },
    handleDownloadSp() {
      this.downloadForm = {};
      if (this.$refs.downloadForm !== undefined) {
        this.$refs.downloadForm.resetFields();
      }
      this.confirmLoading = false;
      this.dlgDownloadVisible = true;
    },
    handleBgeinDownload() {
      this.$refs.downloadForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          const url =
            // window._config["baseUrl"] +
            "/api/csias/file/downloadSpNew?" +
            "collectedTimeBegin=" +
            this.downloadForm.collectedTimeBegin +
            "&collectedTimeEnd=" +
            this.downloadForm.collectedTimeEnd +
            "&mode=" +
            this.downloadForm.mode;
          axios({
            method: "GET",
            url: url,
            headers: {
              Authorization: "Bearer " + getToken(),
              "Content-Type": "application/json",
            },
            // responseType: "arraybuffer",
            timeout: 6000000,
          }).then(
            (response) => {
              if (response.status === 200 && response.data && response.data.code == 0) {
                let fileName = "散拍图片" + Date.parse(new Date()) + ".rar";
                var link = document.createElement("a");
                link.href = response.data.data + "&filename=" + fileName;
                link.download = fileName;
                link.target = "_blank";
                link.click();
                //释放内存
                window.URL.revokeObjectURL(link.href);
                this.$notify.success({
                  title: "成功",
                  message: "下载文件成功！",
                });
              } else {
                this.$notify.error({
                  title: "错误",
                  message: "下载失败，未查询到照片信息",
                });
              }
              this.confirmLoading = false;
            },
            (err) => {
              this.confirmLoading = false;
              this.$notify.error({
                title: "错误",
                message: "下载失败，未查询到照片信息",
              });
            }
          );
        }
      });
    },
    handleCencelPay(id) {
      this.$confirm("是否确认取消订单并退款吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = { id: id };
          cencelPay(data)
            .then((resp) => {
              if (resp.data.code == 0) {
                this.$notify({
                  title: "取消订单成功",
                  message: resp.message,
                  type: "success",
                });
              } else {
                this.$notify({
                  title: "取消订单失败",
                  message: resp.data.msg,
                  type: "info",
                });
              }
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    handleDelete() {
      if (this.tableSelection === null || this.tableSelection.length === 0) {
        this.$notify({
          title: "警告",
          message: "选择的项不能为空",
          type: "warning",
        });
        return;
      }

      this.$confirm("此操作将删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = [];
          for (const item of this.tableSelection) {
            data.push(item.id);
          }
          deleteOrder(data)
            .then((resp) => {
              this.$notify({
                title: "删除成功",
                message: resp.message,
                type: "success",
              });
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },

    handleView(id) {
      if (this.$refs.orderInfoFrom != undefined) {
        this.$refs.orderInfoFrom.initData(id, "sp");
      }
    },
    // 物流查询
    handleLogistics(data) {
      this.logisticsList = [];
      let traceNo = data.mailNo;
      this.logisticsVisible = true;
      getLogistics(traceNo).then((resp) => {
        if (resp.data.errorDesc == "当前邮件暂无轨迹信息！") {
          this.logistics = 0;
          this.courierNumber = "暂无";
          this.logisticsCompany = "暂无";
        } else {
          this.courierNumber = data.mailNo;
          this.logisticsCompany = "EMS";
          this.logistics = 1;
          this.logisticsList = resp.data.data;
        }
      });
    },
    //上传学生照片
    submitUpload() {
      this.uploadLoading = true;
      //this.$refs.upload.submit();
      if (this.$refs.upload) {
        this.$refs.upload.clearFiles(); // 清除上传的文件
      }
      this.fileList = [];

      this.$refs.fileForm.validate((valid) => {
        if (valid) {
          let dataPar = this.fileForm;
          let fdParams = new FormData();
          fdParams.append("id", this.orderId);
          fdParams.append("isFaceDetect", dataPar.isFaceDetect);
          fdParams.append("file", dataPar.file.raw);
          fdParams.append("type", dataPar.type);
          if (this.isReImport) {
            reUploadPhoto(fdParams).then((resp) => {
              if (resp.data.code == 0) {
                this.importMsg = resp.data.msg;
                this.importSatus = "success";
                this.dlgImportVisible = false;
                this.$notify({
                  title: "上传照片成功！",
                  message: resp.message,
                  type: "success",
                });
              } else {
                this.importMsg = resp.data.msg;
                this.importSatus = "error";
              }
              this.uploadLoading = false;
              this.msgVisible = false;
              this.query();
            });
          } else {
            uploadPhoto(fdParams).then((resp) => {
              if (resp.data.code == 0) {
                this.importMsg = resp.data.msg;
                this.importSatus = "success";
                this.dlgImportVisible = false;
                this.$notify({
                  title: "上传照片成功！",
                  message: resp.message,
                  type: "success",
                });
              } else {
                this.importMsg = resp.data.msg;
                this.importSatus = "error";
              }
              this.uploadLoading = false;
              this.msgVisible = false;
              this.query();
            });
          }
        }
      });
    },

    //省
    getProvice() {
      getRegionsByParentId("0")
        .then((resp) => {
          if (resp.data) {
            this.proviceOptions = resp.data;
          }
        })
        .catch((error) => {});
    },
    //市
    getCity(proviceCode) {
      getRegionsByParentId(proviceCode)
        .then((resp) => {
          if (resp.data) {
            this.cityOptions = resp.data;
            this.mailForm.regionCity = resp.data[0].id;
            this.getCounty(this.mailForm.regionCity);
          }
        })
        .catch((error) => {});
    },
    //县
    getCounty(cityCode) {
      getRegionsByParentId(cityCode)
        .then((resp) => {
          if (resp.data) {
            this.countyOptions = resp.data;
            this.mailForm.regionCounty = resp.data[0].id;
          }
        })
        .catch((error) => {});
    },

    handleRemove(file, fileList) {
      if (!fileList.length) {
        this.hasFile = false;
      }
      this.fileForm.file = null;
    },
    handlePreview(file) {
      //预览
      console.log(file);
    },
    handleChange(file, fileList) {
      if (fileList.length >= 2) {
        return;
      }
      if (fileList.length === 1) {
        this.hasFile = true;
      }
      this.uploadImg = file.url;
      this.fileForm.file = file;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },
    columnDateFormat(row, column, cellValue, index) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd HH:MM:SS", new Date(dateValue));
      }
    },
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    formDateTimeFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd HH:MM:SS", new Date(dateValue));
      }
    },
    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "ph") {
        return formatPh(str);
      } else if (mod == "name") {
        return formatName(str);
      } else if (mod == "Idcard") {
        return formatIdCard(str);
      }
    },
  },
};
</script>

<style>
.tip {
  padding: 8px 16px;
  background-color: #f9f9f9;
  border-radius: 4px;
  border-left: 5px solid #446cf3;
  margin: 5px 0;
  line-height: 20px;
}

.rowGray .cell,
.rowBlank .cell {
  padding: 0 5px;
  border: #e8e8e8 1px solid;
  height: 35px;
  line-height: 35px;
  vertical-align: bottom;
}

.divice {
  width: 1200px;
}

.divice .col-1eft {
  float: left;
  width: 900px;
  height: 160px;
}

.divice .col-right {
  float: left;
  margin-left: 5px;
  width: 200px;
  height: 150px;
}

.clear-float {
  clear: both;
}

.photo {
  width: 120px;
  height: 160px;
  position: relative;
  vertical-align: top;
}

.photo::before {
  content: " ";
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
}
.photo-box .item {
  text-align: center;
}
.photo-box .item .desc {
  line-height: 30px;
  text-align: center;
}
.photo-box .item .photo {
  margin-right: 0;
}

.logistics_box {
  max-height: 700px;
  overflow: auto;
  width: 800px;
  margin: 0 auto;
  color: #333;
  font-weight: bold;
}
.rowGray .cell,
.rowBlank .cell {
  padding: 0 5px;
  border: #e8e8e8 1px solid;
  height: 40px;
  line-height: 40px;
  vertical-align: bottom;
}

.rowGray .cell {
  background: #f2f2f2;
}
</style>
